import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import {
  containerMaxWidth,
  MEDIA_MIN_X_LARGE,
  MEDIA_MIN_XX_LARGE
} from "../../constants"
import Link from "../../components/Link"

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 1.7rem 2rem 1.5rem;

  ${MEDIA_MIN_XX_LARGE} {
    padding: 1.8rem 0 1.6rem;
  }
`

const Container = styled.div`
  width: 100%;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  font-size: 1.1rem;
  max-width: ${containerMaxWidth};
  margin: 0 auto;
  line-height: 1.6rem;
  position: relative;
  color: ${(p) =>
    p.enableDarkMode ? p.theme.colors.white : p.theme.colors.black};

  ${MEDIA_MIN_X_LARGE} {
    font-size: 1.2rem;
  }

  a {
    color: ${(p) =>
      p.enableDarkMode ? p.theme.colors.white : p.theme.colors.black};
  }
`

const Divider = styled.span`
  margin: 0 0.8rem;
  color: ${(p) =>
    p.enableDarkMode ? p.theme.colors.white : p.theme.colors.black};
`

const Breadcrumbs = ({
  location,
  children,
  breadcrumbs,
  enableDarkMode,
  ...other
}) => {
  const { t } = useTranslation("translations")

  return (
    <Wrapper {...other}>
      <Container enableDarkMode={enableDarkMode}>
        <Link to="/">{t("Home")}</Link>
        {breadcrumbs?.map((breadcrumb) => (
          <React.Fragment key={breadcrumb.slug}>
            <Divider enableDarkMode={enableDarkMode}>/</Divider>
            <Link key={breadcrumb.slug} to={breadcrumb.slug}>
              {breadcrumb.name}
            </Link>
          </React.Fragment>
        ))}
      </Container>
    </Wrapper>
  )
}

export default Breadcrumbs
